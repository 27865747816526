import React from "react"
import { useTheme } from "emotion-theming"
import { Block, Grid } from "@sonos-inc/web-ui-core"
import Link from "./Link"
import { H1 } from "./typography"

export default function NotFoundPageContent() {
  const theme = useTheme()
  return (
    <Grid
      gridTemplateColumns={`2fr repeat(${theme.grid.columns - 4}, 1fr) 2fr`}
      mt={["149px", null, null, "58px"]}
    >
      <Block gridColumn={["4/23", null, null, "8 / 20"]} textAlign="center">
        <H1>We are sorry, this page doesn’t exist.</H1>
        <Link customStyles={{ textDecoration: "underline" }} href="/">
          Come back to the Home Page
        </Link>
      </Block>
    </Grid>
  )
}
