import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import NotFoundPageContent from "../components/NotFoundPageContent"

export default function NotFound() {
  return (
    <Layout>
      <SEO title="404 not found" />
      <NotFoundPageContent />
    </Layout>
  )
}
